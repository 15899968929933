export const errorMessages = [
  {
    status: "404",
    head: "UH OH! You're lost.",
    content:
      " The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.",
    buttonName: "Home",
    buttonRoute: "/",
    image: "NotFound",
  },
];
export const category = [
  { id: "1", value: "Information Technology" },
  { id: "2", value: "Mathematics" },
  { id: "3", value: "Sports" },
  { id: "4", value: "Agriculture" },
  { id: "5", value: "Medial" },
];
export const instructorList = [
  { id: 1, name: "Kumaran", subject: "Physics" },
  { id: 2, name: "Ravi", subject: "Mathematics" },
  { id: 3, name: "Maniyam", subject: "Chemistry" },
  { id: 4, name: "Thyaparan", subject: "Biology" },
];
export const colors = [
  { backgroundColor: "#f038b956" },
  { backgroundColor: "#2348ec56" },
  { backgroundColor: "#23eca956" },
  { backgroundColor: "#7dec2356" },
  { backgroundColor: "#3023ec56" },
  { backgroundColor: "#bc38f056" },
  { backgroundColor: "#f0383856" },
  { backgroundColor: "#ec852356" },
  { backgroundColor: "#ecd82356" },
  { backgroundColor: "#7a521c56" },
];
export const colors1 = [
  { backgroundColor: "#f038a3f3" },
  { backgroundColor: "#5e9ef3c4" },
 
  { backgroundColor: "#3023ecce" },
  { backgroundColor: "#bc38f0da" },
  { backgroundColor: "#f03838ea" },
  { backgroundColor: "#ec8423e8" },
  { backgroundColor: "#ecd823f3" },
  { backgroundColor: "#23eca9e7" },
  { backgroundColor: "#7dec23e8" },
  { backgroundColor: "rgb(212, 156, 72)" },
];
export const monthDefault = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

 export const eventSlotSample = [
  {
    slotId: 1,
    start: "8:00 am",
    end: "9:00 am",
    slot: "morning",
    select: false,
  },
  {
    slotId: 2,
    start: "9:00 am",
    end: "10:00 am",
    slot: "morning",
    select: false,
  },
  {
    slotId: 3,
    start: "10:00 am",
    end: "11:00 am",
    slot: "morning",
    select: false,
  },
  {
    slotId: 4,
    start: "11:00 am",
    end: "12:00 pm",
    slot: "morning",
    select: false,
  },
  {
    slotId: 5,
    start: "1:00 pm",
    end: "2:00 pm",
    slot: "evening",
    select: false,
  },
  {
    slotId: 6,
    start: "2:00 pm",
    end: "3:00 pm",
    slot: "evening",
    select: false,
  },
  {
    slotId: 7,
    start: "3:00 pm",
    end: "4:00 pm",
    slot: "evening",
    select: false,
  },
  {
    slotId: 8,
    start: "4:00 pm",
    end: "5:00 pm",
    slot: "evening",
    select: false,
  },
  {
    slotId: 9,
    start: "5:00 pm",
    end: "6:00 pm",
    slot: "evening",
    select: false,
  },
  {
    slotId: 10,
    start: "6:00 pm",
    end: "7:00 pm",
    slot: "night",
    select: false,
  },
  {
    slotId: 11,
    start: "7:00 pm",
    end: "8:00 pm",
    slot: "night",
    select: false,
  },
  {
    slotId: 12,
    start: "8:00 pm",
    end: "9:00 pm",
    slot: "night",
    select: false,
  },
  {
    slotId: 13,
    start: "9:00 pm",
    end: "10:00 pm",
    slot: "night",
    select: false,
  },
];
