export const template1 =
  '<h1><span style="font-size: 48px;"><u>The History of Languages</u></span></h1><pre><span style="font-size: 28px;">A language is a structured system of communication.<br>The type of communication that involves the use of words.</span></pre>' +
  '<h2><span style="font-size: 36px;">What is a Language?</span></h2>' +
  "<ul>" +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Apes and Whales communicate with each other.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Birds and Bees communicate with each other.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">But only humans have developed a real Language.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">No other species can express ideas using sentences constructed by a set of words (with verbs and nouns).</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">This skill is remarkable. And what is even more remarkable: Even children master this skill.</span></li>' +
  "</ul>" +
  "<hr>" +
  '<h2><span style="font-size: 36px;">Spoken Languages</span></h2>' +
  '<div className="se-component se-image-container __se__float-right" contentEditable="false">' +
  '<figure style="margin: auto;">' +
  '<img src="https://www.w3schools.com/ai/img_babel_tower.jpg" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="," data-align="right" data-percentage="auto,auto" data-file-name="img_babel_tower.jpg" data-file-size="0" origin-size="640,468" data-origin="," style="" data-index="9">' +
  "</figure>" +
  "</div>" +
  '<h2><span style="font-size: 36px;">​</span></h2>' +
  "<ol>" +
  '<li style="font-size: 28px;"><span style="font-family: Arila; font-size: 28px;">We are not sure of how old the spoken language is. The topic is difficult to study because of the lack of evidence.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-family: Arila; font-size: 28px;">We don&apos;t know how it started. But we have a clue.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-family: Arila; font-size: 28px;">The great African apes, Pan and Gorilla, are our closest living relatives. Why are they called &quot;Apes&quot;? Because they ape. Apes mime to get their message across.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-family: Arila; font-size: 28px;">It is assumed that the evolution of languages must have been a long process. Our ancestors might have started speaking a million years ago, but with fewer words, more miming, and no grammar.</span></li>' +
  '<li style="font-size: 28px;"><span style="font-family: Arila; font-size: 28px;">The Tower of Babel</span></li>' +
  "</ol>" +
  "<p></p>" +
  '<h2><span style="font-size: 28px;"><br>' +
  "</span></h2>" +
  '<h2><span style="font-size: 48px;">Cognitive Development</span></h2>' +
  '<p><span style="font-size: 28px;">According to&nbsp;<a href="https://scholar.google.no/scholar?q=The+Faculty+of+Language:+What+Is+It,+Who+Has+It,+and+How+Did+It+Evolve%3F&amp;hl=en&amp;as_sdt=0&amp;as_vis=1&amp;oi=scholart">Hauser, Chomsky, and Fitch (2002)</a>, there are six aspects of language development:</span></p>' +
  "<ul>" +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Theory of Mind</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Understanding Vocal Signals</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Understanding Imitation</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Understanding Numbers</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Understanding Intentional Communication</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Understanding Non-linguistic Representations</span></li>' +
  "</ul>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Human Languages</span></h2>' +
  '<p><span style="font-size: 28px;">Human languages contain a limited set of&nbsp;<strong>Words&nbsp;</strong>put together in&nbsp;<strong>Sentences</strong>:</span></p>';

export const template2 =
  '<h1><span style="box-sizing: border-box; font-size: 48px; -webkit-user-drag: none; overflow: visible; font-family: inherit; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px;"><span style="box-sizing: border-box; font-size: inherit; -webkit-user-drag: none; overflow: visible; font-family: &quot;Comic Sans MS&quot;; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px;"><u><strong>Artificial Intelligence</strong></u></span></span></h1>' +
  '<p><span style="box-sizing: border-box; font-size: 26px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px;">What is Artificial Intelligence?</span></p>' +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; font-family: inherit; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px;"><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: rgb(0, 0, 0); vertical-align: baseline; margin: 0px; padding: 0px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; font-family: &quot;Helvetica Neue&quot;; display: inline !important;">Artificial Intelligence suggest that machines can mimic humans in:</span></span></p>' +
  "<ul>" +
  '<li style="color: inherit; font-size: 22px;"><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Talking</span></li>' +
  '<li style="color: inherit; font-size: 22px;"><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Thinking</span></li>' +
  '<li style="color: inherit; font-size: 22px;"><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Learning</span></li>' +
  '<li style="color: inherit; font-size: 22px;"><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Planning</span></li>' +
  '<li style="color: inherit; font-size: 22px;"><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Understanding<br>' +
  "</span></li>" +
  "</ul>" +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Artificial Intelligence is also called Machine Intelligence and Computer Intelligence.</span></p>' +
  "<hr>" +
  ' <h3><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Our Definition:</span></h3>' +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Artificial intelligence is a system that can solve a problem optimally. This means that the system can figure out alone what is the best action to take.</span></p>' +
  "<hr>" +
  '<h3><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Wikipedia:</span></h3>' +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">AI is intelligence demonstrated by machines, unlike natural intelligence displayed by humans and animals, which involves consciousness and emotionality.</span></p>' +
  "<hr>" +
  '<h3><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Investopedia:</span></h3>' +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">AI refers to the simulation of human intelligence in machines that are programmed to think like humans and mimic their actions.</span></p>' +
  "<hr>" +
  '<h3><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Britannica:</span></h3>' +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">AI is the ability of a digital computer or computer-controlled robot to perform tasks commonly associated with intelligent beings. The term is frequently applied to the project of developing systems endowed with the intellectual processes characteristic of humans, such as the ability to reason, discover meaning, generalize, or learn from past experience.</span></p>' +
  "<hr>" +
  '<h2><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Interesting Questions</span></h2>' +
  '<p><span style="box-sizing: border-box; font-size: 22px; -webkit-user-drag: none; overflow: visible; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px; font-family: &quot;Helvetica Neue&quot;;">Studying AI raises many interesting questions:</span></p>';

export const template3 =
  '<h1><span style="box-sizing: border-box; font-size: 48px; -webkit-user-drag: none; overflow: visible; font-family: inherit; color: inherit; display: inline; vertical-align: baseline; margin: 0px; padding: 0px;"><u>The History of Languages</u></span></h1>' +
  '<h2><span style="font-size: 36px;">The First Abacus</span></h2>' +
  '<p><span style="font-size: 28px;">The&nbsp;<strong>Babylonian Abacus</strong>&nbsp;was developed to&nbsp;<strong>Reduce the Time</strong>&nbsp;to perform calculations.</span></p>' +
  '<p><span style="font-size: 28px;">As stated in the previous chapter, we believe that the Babylonians invented complex counting.</span></p>' +
  '<p><span style="font-size: 28px;">The period <span className="textShadow3">2700–2300 BC</span> probably saw the first appearance of an abacus, a table of successive columns which defined the orders of a&nbsp;<strong>60 digits</strong>&nbsp;number system.</span></p>' +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Abacus 2.0</span></h2>' +
  '<p><span style="font-size: 28px;">The&nbsp;<strong>Roman Abacus</strong>&nbsp;used 10 digits Roman numbers to&nbsp;<strong>Reduce the Time</strong>&nbsp;to perform calculations:</span></p>' +
  '<div className="se-component se-image-container __se__float-none" contentEditable="false">' +
  '<figure style="margin: 0px;">' +
  '<img alt="Abacus" src="https://www.w3schools.com/ai/img_roman_abacus.jpg" data-proportion="true" data-align="none" data-index="1" data-file-name="img_roman_abacus.jpg" data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" style="">' +
  "</figure>" +
  "</div>" +
  '<p><span style="font-size: 28px;">Image: 1911 Encyclopedia Britannica (public domain).</span></p>' +
  '<p><span style="font-size: 28px;">The Romans developed the Roman Abacus, a portable, base-10 version of earlier abacuses used by the Babylonians.</span></p>' +
  '<p><span style="font-size: 28px;">This was the worlds first handheld computer. Used by Roman engineers, merchants and tax collectors.</span></p>' +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Analog Computers</span></h2>' +
  "<pre>" +
  '<span style="font-size: 28px;"><strong>The Difference Engine</strong>&nbsp;(Charles Babbage 1822) was a mechanical machine designed to&nbsp;<strong>Reduce the Time</strong>&nbsp;to calculate complex mathematical functions.<br>' +
  '<strong>The Analytical Engine</strong><span style="color: rgb(51, 51, 51); font-family: Arila; font-size: 28px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 100; letter-spacing: 0.5px; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">&nbsp;(Charles Babbage 1833) was a mechanical machine designed with modern computer elements like arithmetic, logic, and memory.</span><br>' +
  "</span>" +
  "</pre>" +
  "<p><br>" +
  "</p>" +
  '<p><span style="font-size: 28px;">Both these &quot;computers&quot; used 10 digit (decimal) mechanical cogwheels to perform mathematical calculations:</span></p>' +
  '<div className="se-component se-image-container __se__float- __se__float-none" contentEditable="false">' +
  '<figure style="margin: 0px;">' +
  '<a href="https://commons.wikimedia.org/wiki/File:AnalyticalMachine_Babbage_London.jpg" alt="https://commons.wikimedia.org/wiki/File:AnalyticalMachine_Babbage_London.jpg" data-image-link="image"></a>    <a href="https://commons.wikimedia.org/wiki/File:AnalyticalMachine_Babbage_London.jpg" alt="https://commons.wikimedia.org/wiki/File:AnalyticalMachine_Babbage_London.jpg" data-image-link="image"><img alt="Analytical Machine" src="https://www.w3schools.com/ai/img_analytical_machine.jpg" data-image-link="https://commons.wikimedia.org/wiki/File:AnalyticalMachine_Babbage_London.jpg" data-proportion="true" data-align="none" data-index="2" data-file-name="img_analytical_machine.jpg" data-file-size="0" data-origin="," data-size="787px," data-rotate="" data-rotatex="" data-rotatey="" style="width: 787px;">' +
  "</a>" +
  '<figcaption contentEditable="true">' +
  '<div>​<span style="color: rgb(51, 51, 51); font-family: Arila; font-size: 28px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 100; letter-spacing: 0.5px; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">(Charles Babbage&apos;s Analytical Engine. Science Museum. London)</span>​</div>' +
  "</figcaption>" +
  "</figure>" +
  "</div>" +
  "<p></p>" +
  "<p><br>" +
  "</p>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Digital Computers</span></h2>' +
  '<p className="__se__p-spaced"><span style="font-size: 28px;">Digital Computers use 0/1 switches to perform calculations. They operate on&nbsp;<strong>binary</strong>&nbsp;values like 11100110 in contrast to&nbsp;<strong>analog</strong>&nbsp;values like 230.</span></p>' +
  "<p>​</p>" +
  '<p><span style="font-size: 28px;">The first Electric Digital Computer was designed and built by Konrad Zuse in Germany (1941).</span></p>' +
  '<p><span style="font-size: 28px;">It used 2600 electrical relays as 0/1 switches. The clock speed was about 5 Hz.</span></p>' +
  '<div className="se-component se-image-container __se__float- __se__float-none" contentEditable="false">' +
  '<figure style="margin: 0px;">' +
  '<a href="https://commons.wikimedia.org/wiki/File:Z3_Deutsches_Museum.JPG" alt="https://commons.wikimedia.org/wiki/File:Z3_Deutsches_Museum.JPG" data-image-link="image"></a>    <a href="https://commons.wikimedia.org/wiki/File:Z3_Deutsches_Museum.JPG" alt="https://commons.wikimedia.org/wiki/File:Z3_Deutsches_Museum.JPG" data-image-link="image"><img alt="Z3 Zuse" src="https://www.w3schools.com/ai/img_z3_zuse.jpg" data-image-link="https://commons.wikimedia.org/wiki/File:Z3_Deutsches_Museum.JPG" data-proportion="true" data-align="none" data-index="3" data-file-name="img_z3_zuse.jpg" data-file-size="0" data-origin="," data-size="640px,365px" data-rotate="" data-rotatex="" data-rotatey="" style="width: 640px; height: 365px;">' +
  "</a>" +
  '<figcaption contentEditable="true">' +
  '<div>​<span style="color: rgb(51, 51, 51); font-family: Arila; font-size: 28px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 100; letter-spacing: 0.5px; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">Replica of the Zuse Z3. Deutsches Museum. Munich.</span>​</div>' +
  "</figcaption>" +
  "</figure>" +
  "</div>" +
  "<p></p>" +
  "<p><br>" +
  "</p>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Electronic Computers</span></h2>' +
  '<p><span style="font-size: 28px;"><strong>First generation Computers</strong>&nbsp;(1945-1950) used vacuum tubes as binary switches.</span></p>' +
  '<p><span style="font-size: 28px;">Vacuum tubes are much faster than electrical relays.</span></p>' +
  '<p><span style="font-size: 28px;">The clock speed of these computers was between 500 KHz and 1 Mhz.</span></p>' +
  '<div className="se-component se-image-container __se__float-none" contentEditable="false">' +
  '<figure style="margin: 0px; width: 416px;">' +
  '<a href="https://commons.wikimedia.org/wiki/File:CEP_arithmetic_part_close_up.jpg" alt="https://commons.wikimedia.org/wiki/File:CEP_arithmetic_part_close_up.jpg" data-image-link="image"><img alt="Vacuum Tubes" src="https://www.w3schools.com/ai/img_vacuum_tubes.jpg" data-image-link="https://commons.wikimedia.org/wiki/File:CEP_arithmetic_part_close_up.jpg" data-proportion="true" data-align="none" data-index="4" data-file-name="img_vacuum_tubes.jpg" data-file-size="0" data-origin="," data-size="416px,312px" data-rotate="" data-rotatex="" data-rotatey="" style="width: 416px; height: 312px;">' +
  "</a>" +
  "</figure>" +
  "</div>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Second Generation Computers</span></h2>' +
  '<p><span style="font-size: 28px;"><strong>Second generation Computers</strong>&nbsp;(1950-1960) used transistors as binary 0/1 switches.</span></p>' +
  '<p><span style="font-size: 28px;">Transistors are much faster than vacuum tubes.</span></p>' +
  '<div className="se-component se-image-container __se__float-none" contentEditable="false">' +
  '<figure style="margin: 0px;">' +
  '<a href="https://commons.wikimedia.org/wiki/File:MIT_TX-0_computer_Philco_surface-barrier_transistors.JPG" alt="https://commons.wikimedia.org/wiki/File:MIT_TX-0_computer_Philco_surface-barrier_transistors.JPG" data-image-link="image"><img alt="MIT TX-0 Transistors" src="https://www.w3schools.com/ai/img_computer_transistors.jpg" data-image-link="https://commons.wikimedia.org/wiki/File:MIT_TX-0_computer_Philco_surface-barrier_transistors.JPG" data-proportion="true" data-align="none" data-index="5" data-file-name="img_computer_transistors.jpg" data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" style="">' +
  "</a>" +
  "</figure>" +
  "</div>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Third Generation Computers</span></h2>' +
  '<p><span style="font-size: 28px;"><strong>Third generation Computers</strong>&nbsp;(1960) used integrated circuits as binary switches.</span></p>' +
  '<p><span style="font-size: 28px;">Integrated circuits are much faster than transistors.</span></p>' +
  '<div className="se-component se-image-container __se__float- __se__float-none" contentEditable="false">' +
  '<figure style="margin: 0px;">' +
  '<a href="https://commons.wikimedia.org/wiki/File:Integrated_circuits_(1).jpg" alt="https://commons.wikimedia.org/wiki/File:Integrated_circuits_(1).jpg" data-image-link="image"><img alt="Integrated Circuits" src="https://www.w3schools.com/ai/img_integrated_circuits.jpg" data-image-link="https://commons.wikimedia.org/wiki/File:Integrated_circuits_(1).jpg" data-proportion="true" data-align="none" data-index="6" data-file-name="img_integrated_circuits.jpg" data-file-size="0" data-origin="," data-size="," data-rotate="" data-rotatex="" data-rotatey="" data-percentage="auto,auto" style="transform: rotate(0deg);">' +
  "</a>" +
  "</figure>" +
  "</div>" +
  "<p></p>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">Computer Speed</span></h2>' +
  '<p><span style="font-size: 28px;">The first electrical computer could do 5 instructions per second.</span></p>' +
  '<p><span style="font-size: 28px;">The first electronic computer did 5000 instructions per second.</span></p>' +
  '<p><span style="font-size: 28px;">The first PC did 5 million instructions per second.</span></p>' +
  '<p><span style="font-size: 28px;">AMD was the first PC to reach 1 billion instructions per second.</span></p>' +
  '<p><span style="font-size: 28px;">Today, IPhone 12 can do 11 billion instructions per second.</span></p>' +
  "<table>" +
  "<tbody>" +
  "<tr>" +
  "<th>" +
  '<div><span style="font-size: 28px;">Year</span></div>' +
  "</th>" +
  "<th>" +
  '<div><span style="font-size: 28px;">Computer</span></div>' +
  "</th>" +
  "<th>" +
  '<div><span style="font-size: 28px;">Instructions<br>per Second</span></div>' +
  "</th>" +
  "<th>" +
  '<div><span style="font-size: 28px;">Bits<br>per Instruction</span></div>' +
  "</th>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><span style="font-size: 28px;">1941</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">&nbsp;Z3</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">5</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">4</span></div>' +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><span style="font-size: 28px;">1945</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">&nbsp;ENIAC</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">5.000</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">8</span></div>' +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><span style="font-size: 28px;">1981</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">&nbsp;IBM PC</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">5.000.000</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">16</span></div>' +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><span style="font-size: 28px;">1995</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">&nbsp;Intel Pentium PC</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">100.000.000</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">32</span></div>' +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  ' <div><span style="font-size: 28px;">2000</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">&nbsp;AMD PC</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">1.000.000.000</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">64</span></div>' +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><span style="font-size: 28px;">2020</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;"><br>' +
  "</span></div>" +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">10.000.000.000</span></div>' +
  "</td>" +
  "<td>" +
  '<div><span style="font-size: 28px;">128</span></div>' +
  "</td>" +
  "</tr>" +
  "</tbody>" +
  "</table>" +
  "<hr>" +
  '<h2><span style="font-size: 28px;">When Did it Start? Where Will it End?</span></h2>' +
  '<p><span style="font-size: 28px;">From the history above you can extract:</span></p>' +
  "<ul>" +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Computers were designed to do calculations</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Charles Babbage created the first mechanical computer</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">The first digital computers used electromechanical switches</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Vacuum tubes are much faster than electromechanical switches</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Transistors are much faster than vacuum tubes</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Integrated circuits are much faster than transistors</span></li>' +
  '<li style="font-size: 28px;"><span style="font-size: 28px;">Tomorrow&apos;s computes will be faster than the human brain?</span></li>' +
  "</ul>" +
  '<p><span style="font-size: 28px;"><em>&quot;Computers are useless because they can only give you answers.&quot;</em></span></p>' +
  '<p><span style="font-size: 28px;">Pablo Picasso</span></p>' +
  '<p><span style="font-size: 28px;">​</span></p>';

export const template4 =
  '<p>​<br></p><p><span style="font-size: 48px;"><u><strong>SQL Keywords Reference</strong></u></span></p><p><span style="font-size: 48px;"><u><strong><br></strong></u></span></p><pre>This SQL keywords reference contains the reserved words in SQL.<br>SQL Keywords<br></pre><p><br></p>' +
  "<table>" +
  "<tbody>" +
  "<tr>" +
  "<th>" +
  '<div><span style="text-align: inherit; font-weight: 400;">Keyword</span><br>' +
  "</div>" +
  "</th>" +
  "<th>" +
  "<div>Description</div>" +
  "</th>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_add.asp">ADD</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Adds a column in an existing table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_add_constraint.asp">ADD CONSTRAINT</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Adds a constraint after a table is already created</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_alter.asp">ALTER</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Adds, deletes, or modifies columns in a table, or changes the data type of a column in a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_alter_column.asp">ALTER COLUMN</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Changes the data type of a column in a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_alter_table.asp">ALTER TABLE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Adds, deletes, or modifies columns in a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_all.asp">ALL</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Returns true if all of the subquery values meet the condition</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_and.asp">AND</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Only includes rows where both conditions is true</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_any.asp">ANY</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Returns true if any of the subquery values meet the condition</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_as.asp">AS</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Renames a column or table with an alias</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_asc.asp">ASC</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Sorts the result set in ascending order</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_backup_database.asp">BACKUP DATABASE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a back up of an existing database</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_between.asp">BETWEEN</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Selects values within a given range</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_case.asp">CASE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates different outputs based on conditions</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_check.asp">CHECK</a></div>' +
  "</td>" +
  "<td>" +
  "<div>A constraint that limits the value that can be placed in a column</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_column.asp">COLUMN</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Changes the data type of a column or deletes a column in a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_constraint.asp">CONSTRAINT</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Adds or deletes a constraint</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create.asp">CREATE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a database, index, view, table, or procedure</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_database.asp">CREATE DATABASE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a new SQL database</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_index.asp">CREATE INDEX</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates an index on a table (allows duplicate values)</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_or_replace_view.asp">CREATE OR REPLACE VIEW</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Updates a view</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_table.asp">CREATE TABLE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a new table in the database</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_procedure.asp">CREATE PROCEDURE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a stored procedure</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_unique_index.asp">CREATE UNIQUE INDEX</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a unique index on a table (no duplicate values)</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_create_view.asp">CREATE VIEW</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates a view based on the result set of a SELECT statement</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_database.asp">DATABASE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Creates or deletes an SQL database</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_default.asp">DEFAULT</a></div>' +
  "</td>" +
  "<td>" +
  "<div>A constraint that provides a default value for a column</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_delete.asp">DELETE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes rows from a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_desc.asp">DESC</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Sorts the result set in descending order</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_select_distinct.asp">DISTINCT</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Selects only distinct (different) values</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop.asp">DROP</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes a column, constraint, database, index, table, or view</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_column.asp">DROP COLUMN</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes a column in a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_constraint.asp">DROP CONSTRAINT</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes a UNIQUE, PRIMARY KEY, FOREIGN KEY, or CHECK constraint</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_database.asp">DROP DATABASE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes an existing SQL database</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_default.asp">DROP DEFAULT</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes a DEFAULT constraint</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_index.asp">DROP INDEX</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes an index in a table</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_table.asp">DROP TABLE</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes an existing table in the database</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_drop_view.asp">DROP VIEW</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Deletes a view</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_exec.asp">EXEC</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Executes a stored procedure</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_exists.asp">EXISTS</a></div>' +
  "</td>" +
  "<td>" +
  "<div>Tests for the existence of any record in a subquery</div>" +
  "</td>" +
  "</tr>" +
  "<tr>" +
  "<td>" +
  '<div><a href="https://www.w3schools.com/sql/sql_ref_foreign_key.asp">FOREIGN KEY</a></div>' +
  "</td>" +
  "<td>" +
  "<div>A constraint that is a key used to link two tables together</div>" +
  "</td>" +
  "</tr>" +
  "</tbody>" +
  "</table>" +
  "<pre>" +
  "​​​<br>" +
  "</pre>";
