export const searchText = (source, text) => {
  if (source.search(text) === -1) {
    return false;
  }
  return true;
};
export const cutText = (source, num) => {
  return source.slice(0, num);
};
export function randomArrayShuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}
