import React, { useEffect, useState } from "react";
import "./navbar.css";
import Search from "./search";
import { useSelector, useDispatch } from "react-redux";
import { changeDark, appendActiveNav } from "../../actions";
import { searchText } from "../../common/functions";

const NavBar = (props) => {
  const [menuListOn, setMenuListOn] = useState(false);
  const [mobileMenuListOn, setMobileMenuListOn] = useState(false);
  const [WB, setWB] = useState("");
  const search = useSelector((state) => state.search);
  const navCollection = useSelector((state) => state.navCollection);
  const activeNav = useSelector((state) => state.activeNav);
  const dark = useSelector((state) => state.isDark);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("click", function (event) {
      var className = event.target.className;
      if (
        !searchText(className, "nav-link") &&
        !searchText(className, "menuList") &&
        !searchText(className, "nav-list-st") &&
        !searchText(className, "nav-list-list") &&
        !searchText(className, "nav-list") &&
        !searchText(className, "menuIcon")
      ) {
        setMenuListOn(false);
        setMobileMenuListOn(false);
      }
    });
  }, []);
  const menuClick = (clickName, on, web) => {
    if (clickName === "close") {
      setMenuListOn(false);
      dispatch(
        appendActiveNav({ navMain: "Home", navSub: "", navContent: "" })
      );
    } else if (clickName.navMain === "Home") {
      setMenuListOn(!menuListOn);
      setWB("web");
      
      dispatch(
        appendActiveNav({ navMain: "Home", navSub: "", navContent: "" })
      );
    } else if (clickName.navMain === "RealHome") {
      setMenuListOn(false);
    } else {
      setMenuListOn(
        activeNav.navMain !== clickName.value ||
          !on ||
          activeNav.navMain !== clickName.value ||
          (on && activeNav.navMain !== clickName.value)
          ? true
          : false
      );
      dispatch(
        appendActiveNav({
          navMain: clickName.value,
          navSub: clickName.list[0].value,
          navContent: clickName.list[0].list[0].value,
        })
      );
      setWB(web);
    }
  };
  const mobileMenuClick = (clickName, on) => {
    if (clickName === "close") {
      setMobileMenuListOn(false);
      dispatch(
        appendActiveNav({ navMain: "Home", navSub: "", navContent: "" })
      );
    } else if (clickName.navMain === "Home") {
      setMobileMenuListOn(!mobileMenuListOn);
      setWB("mobile");
      dispatch(
        appendActiveNav({ navMain: "Home", navSub: "", navContent: "" })
      );
    } else {
      setMobileMenuListOn(true);
      if (activeNav.navMain === clickName.value) {
        dispatch(
          appendActiveNav({ navMain: "Home", navSub: "", navContent: "" })
        );
      } else {
        dispatch(
          appendActiveNav({
            navMain: clickName.value,
            navSub: clickName.list[0].value,
            navContent: clickName.list[0].list[0].value,
          })
        );
      }
    }
  };
  function mobileMenuListClickStyle() {
    let classes = "mobileMenuList ";
    classes += mobileMenuListOn && WB === "mobile" ? "" : "hide";
    return classes;
  }
  function menuListClickStyle() {
    let classes = "menuList ";
    classes += menuListOn && WB === "web" ? "showMenu" : "hideMenu";
    return classes;
  }
  function menuClickStyle(x) {
    let classes = "navItem menuItem p-0 ";
    classes += activeNav.navMain === x ? "active" : "";
    return classes;
  }
  function navbarStyle() {
    let classes = "navbar navbar-expand-lg ";
    classes += dark && menuListOn ? "navbarLight" : "";
    return classes;
  }
  function hideSearch() {
    let classes = "navItem p-0 ";
    classes += !search ? "showSearch" : "hideSearch";
    return classes;
  }

  return (
    <div className="pos-f-t " id="navbar">
      <nav className={navbarStyle()}>
        <li className="menu navItem">
          <label
            onClick={() =>
              mobileMenuClick({ navMain: "Home", navSub: "", navContent: "" })
            }
            className="nav-link"
          >
            <i
              className="fa fa-caret-square-o-down fa-lg menuIcon"
              aria-hidden="true"
            ></i>{" "}
            <span className="sr-only">(current)</span>
          </label>
        </li>
        <div className="collapse navbar-collapse ">
          <ul className="nav ">
            <li
              onClick={() =>
                menuClick({ navMain: "RealHome", navSub: "", navContent: "" })
              }
              className={menuClickStyle({
                navMain: "Home",
                navSub: "",
                navContent: "",
              })}
              id="Home"
            >
              <label className="nav-link ">
                <i className="fa fa-home fa-lg" aria-hidden="true"></i>{" "}
                <span className="sr-only">(current)</span>
              </label>
            </li>
            {navCollection.map((title, index) => (
              <li className={menuClickStyle(title.value)} key={index}>
                <label
                  onClick={() => menuClick(title, menuListOn, "web")}
                  className="nav-link"
                >
                  {title.value} &nbsp;
                  <i
                    className={
                      menuListOn && title.value === activeNav.navMain
                        ? "fa fa-caret-down  arrowUp"
                        : "fa fa-caret-down"
                    }
                    aria-hidden="true"
                  ></i>
                </label>
              </li>
            ))}
          </ul>
        </div>
        <ul className="nav pull-right pr-0 ">
          <li className={hideSearch()}>
            <label className="nav-link">
              <i className="fa fa-globe fa-sm" aria-hidden="true">
                <span className="sr-only">(current)</span>
              </i>
            </label>
          </li>
          <li className={hideSearch()}>
            <label onClick={() => dispatch(changeDark())} className="nav-link">
              <i className="fa fa-star-half-o fa-sm" aria-hidden="true">
                <span className="sr-only">(current)</span>
              </i>{" "}
            </label>
          </li>
          <Search menuClick={menuClick} />
        </ul>
      </nav>
      <div className={menuListClickStyle()} id="menuList">
        <label
          onClick={() => menuClick("close")}
          className="nav-link-close float-right"
        >
          <i className="fa fa-times fa-sm" aria-hidden="true"></i>{" "}
          <span className="sr-only">(current)</span>
        </label>
        <br></br>
        <div className="row pl-5">
          {navCollection.map((title) =>
            title.value === activeNav.navMain
              ? title.list
                ? title.list.map((listHead, index1) => (
                    <div className="col-sm nav-list-st" key={index1}>
                      <div className="nav-list">{listHead.value}</div>
                      {listHead.list.map((listLast, index2) => (
                        <div className="nav-list-list" key={index2}>
                          <i className="fa fa-gg pr-2 pl-2"></i>
                          {listLast.value}
                        </div>
                      ))}
                    </div>
                  ))
                : ""
              : ""
          )}
        </div>
        <br></br>
      </div>
      <div className={mobileMenuListClickStyle()} id="mobileMenuList">
        <label
          onClick={() => mobileMenuClick("close")}
          className="nav-link-close pull-right text-right menuIcon"
        >
          <i className="fa fa-times fa-sm" aria-hidden="true"></i>{" "}
          <span className="sr-only">(current)</span>
        </label>
        {navCollection.map((title, index3) =>
          title.list ? (
            <div key={index3} className="menuIcon">
              <label
                className={
                  title.value === activeNav.navMain && mobileMenuListOn
                    ? "col-sm text-center nav-link-mobile-select"
                    : "col-sm nav-link-mobile text-center"
                }
                onClick={() => mobileMenuClick(title, mobileMenuListOn)}
              >
                {title.value} &nbsp;
                <i
                  className={
                    title.value === activeNav.navMain && mobileMenuListOn
                      ? "fa fa-caret-up"
                      : "fa fa-caret-down"
                  }
                  aria-hidden="true"
                ></i>
              </label>
              <div
                className={
                  title.value === activeNav.navMain && mobileMenuListOn
                    ? "d-flex justify-content-center mobileMenuListList p-3"
                    : "mobileMenuListList hide"
                }
                id={title.value}
              >
                <div className="row p-3 text-center">
                  {title.list.map((listHead, index) => (
                    <div className="col-sm nav-list-st" key={index}>
                      <div className="nav-list">{listHead.value}</div>
                      {listHead.list.map((listLast, index1) => (
                        <div className="nav-list-list" key={index1}>
                          <i className="fa fa-gg pr-2 pl-2"></i>
                          {listLast.value}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
};
export default NavBar;
