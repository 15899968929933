// Dark
export const changeDark = () => {
  return {
    type: "DARK",
  };
};

//Loader
export const changeLoader = () => {
  return {
    type: "LOADER",
  };
};
// Alert
export const appendAlert = (text,messageType) => {
  return {
    type: "APPEND_ALERT",
    text: text,
    messageType:messageType
  };
};
export const changeAlertStatus = (id) => {
  return {
    type: "TOGGLE_STATUS",
    id: id,
  };
};
export const deleteAlert = (id) => {
  return {
    type: "REMOVE_ALERT",
    id: id,
  };
};
// Authorize
export const toggleAuthorize = () => {
  return {
    type: "TOGGLE_AUTHORIZE",
  };
};
export const authorize = () => {
  return {
    type: "AUTHORIZE",
  };
};
export const unAuthorize = () => {
  return {
    type: "UNAUTHORIZE",
  };
};
// Role
export const changeRole = (role) => {
  return {
    type: "CHANGE_ROLE",
    role: role,
  };
};
export const changeRoleGuest = () => {
  return {
    type: "CHANGE_ROLE_GUEST",
  };
};
export const changeRoleLearner = () => {
  return {
    type: "CHANGE_ROLE_LEARNER",
  };
};
export const changeRoleInstructor= () => {
  return {
    type: "CHANGE_ROLE_INSTRUCTOR",
  };
};
export const changeRoleAdministrator = () => {
  return {
    type: "CHANGE_ROLE_ADMINISTRATOR",
  };
};
export const getRole = () => {
  return {
    type: "GET_ROLE",
  };
};

//User
export const setUser = (user) => {
  return {
    type: "SET_USER",
    user: user,
  };
};
export const getUser = () => {
  return {
    type: "GET_USER",
  };
};
export const removeUser = () => {
  return {
    type: "REMOVE_USER",
  };
};

// navCollection
export const appendNavCollection = (array) => {
  return {
    type: "APPEND_NAV_COLLECTION",
    array: array,
  };
};
export const closeNavCollection = () => {
  return {
    type: "CLOSE_NAV_COLLECTION",
  };
};
export const removeNavCollection = (name) => {
  return {
    type: "REMOVE_NAV_COLLECTION",
    name: name,
  };
};

// Search
export const toggleSearch = () => {
  return {
    type: "TOGGLE_SEARCH",
  };
};
export const openSearch = () => {
  return {
    type: "OPEN_SEARCH",
  };
};
export const closeSearch = () => {
  return {
    type: "CLOSE_SEARCH",
  };
};
// activeNav
export const appendActiveNav = (activeNav) => {
  return {
    type: "APPEND_ACTIVE_NAV",
    activeNav: activeNav,
  };
};
export const updateActiveNav = (nav) => {
  return {
    type: "UPDATE_ACTIVE_NAV",
    nav: nav,
  };
};
export const updateActiveNavSub = (navSub) => {
  return {
    type: "UPDATE_ACTIVE_NAV_SUB",
    navSub: navSub,
  };
};
export const updateActiveNavContent = (navContent) => {
  return {
    type: "UPDATE_ACTIVE_NAV_CONTENT",
    navContent: navContent,
  };
};
export const removeActiveNav = () => {
  return {
    type: "REMOVE_NAV",
  };
};


